@tailwind base;
@tailwind components;
@tailwind utilities;

.bg-header {
    background-color: #232630 !important;
}

.drawer {
    width: 0px;
    transition: width 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
}

.drawer-width {
    width: 220px
}

.router-link {
    @apply w-full p-4 flex items-center relative;
    color: rgba(255, 255, 255, 0.8);
}

.router-link p {
    @apply ml-4 text-base;
}

.router-link:hover, a.active .router-link {
    background-color: rgba(255, 255, 255, 0.1)
}

a.active .router-link:before {
    content: '';
    @apply bg-main-500 h-full absolute top-0 bottom-0 right-0 w-1;
}

.info {
    @apply bg-blue-50;
}

.alternate-table tbody tr:nth-of-type(odd) {
    @apply bg-gray-50;
}

.react-player__preview {
    @apply h-full;
}

.video-aspect {
    aspect-ratio: 16 / 9;
}

.react-simple-star-rating svg {
    display: inline-block !important;
}

.dont-break-out {
    overflow-wrap: break-word;
    word-wrap: break-word;
    -ms-word-break: break-all;
    word-break: break-word;
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto; 
}
